<script setup lang="ts">
  import { useThemeStore } from '@/stores/theme'
  import TheNavbarBottom from './TheNavbarBottom.vue'

  const theme = useThemeStore()
</script>

<template>
  <div :class="[theme.name]">
    <div class="fixed inset-0 bg-primary-600"></div>
    <div class="relative text-onprimary container">
      <RouterView />
      <TheNavbarBottom v-if="$route.meta.navbarBottom" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
</style>
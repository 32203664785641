import { defineStore } from 'pinia'
import themes from '../assets/styles/themes.json'

const defaultTheme = themes[0].name
const localStorageKey = 'theme'

export const useThemeStore = defineStore('Theme', {
  state: () => ({
    name: localStorage.getItem(localStorageKey) ?? defaultTheme,
  }),
  getters: {
    list () {
      return themes.map(t => t.name)
    },
  },
  actions: {
    change (themeName: string) {
      this.name = themeName
      localStorage.setItem(localStorageKey, themeName)
    },
  },
})
<script setup lang="ts">
  import TheToast from '@/components/TheToast.vue'
  import { useFontStore } from '@/stores/font'

  const font = useFontStore()
  font.setSize()
</script>

<template>
  <RouterView />
  <TheToast />
</template>

<style lang="postcss" scoped>
</style>

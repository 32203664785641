<script setup lang="ts">
  import { ChevronLeftIcon } from '@heroicons/vue/24/solid'
  import { RouteLocationRaw, useRouter } from 'vue-router'

  const router = useRouter()

  const props = defineProps<{
    backRoute: RouteLocationRaw,
  }>()

  const back = () => {
    if (window.history.state.position > 1) {
      router.go(-1)
    } else {
      router.push(props.backRoute)
    }
  }
</script>

<template>
  <div class="fixed bg-primary-400 inset-x-0">
    <div class="container flex h-14 items-center">
      <button title="Kembali" @click="back">
        <ChevronLeftIcon class="w-4 h-4" />
      </button>
      <div>
        <img src="/images/logo.png" alt="" class="w-8 ml-4">
      </div>
      <h1 class="text-xl flex-1 font-semibold truncate ml-4">
        <slot></slot>
      </h1>
      <slot name="float-right"></slot>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
</style>
import { defineStore } from 'pinia'

interface State {
  items: {id: string, message: string}[],
}

export const useToastStore = defineStore('Toast', {
  state: (): State => ({
    items: [],
  }),
  actions: {
    add (message: string) {
      // id for v-for key
      this.items.push({ message, id: Date.now().toString() })
      const duration = message.length * 200 // 200ms per letters
      setTimeout(() => {
        this.items.shift()
      }, duration)
    },
  },
})
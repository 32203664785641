import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { usePageStore } from '@/stores/page'
import LayoutMain from '@/views/layouts/LayoutMain.vue'

declare module 'vue-router' {
  interface RouteMeta {
    title: string,
    navbarBottom?: boolean,
  }
}

const routes: RouteRecordRaw[] = [
  getRoute('', [
    {
      path: '',
      component: () => import('@/views/home/PageHome.vue'),
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@/views/home/PageWelcome.vue'),
          meta: { title: 'Home', navbarBottom: true },
        },
        {
          path: 'cari',
          name: 'search',
          component: () => import('@/views/home/PageSearch.vue'),
          meta: { title: 'Cari', navbarBottom: true },
        },
      ],
    },
  ]),
  getRoute('/lagu', [
    {
      path: '',
      name: 'song list',
      component: () => import('@/views/song/PageList.vue'),
      meta: { title: 'Daftar Lagu Pujian', navbarBottom: true },
    },
    {
      path: 'favorit',
      name: 'song favorite list',
      component: () => import('@/views/song/PageFavoriteList.vue'),
      meta: { title: 'Favorit', navbarBottom: true },
    },
    {
      path: ':songId',
      component: () => import('@/views/song/PageDetail.vue'),
      meta: { title: 'Detail Lagu' },
    },
    {
      path: ':songId/:title',
      name: 'song detail',
      component: () => import('@/views/song/PageDetail.vue'),
      meta: { title: 'Detail Lagu' },
    },
  ]),
  getRoute('/persona', [
    {
      path: ':personId',
      component: () => import('@/views/person/PageDetail.vue'),
      meta: { title: 'Persona' },
    },
    {
      path: ':personId/:name',
      name: 'person detail',
      component: () => import('@/views/person/PageDetail.vue'),
      meta: { title: 'Persona' },
    },
  ]),
  {
    path: '/pengaturan',
    component: () => import('@/views/layouts/LayoutSetting.vue'),
    children: [
      {
        path: '',
        name: 'setting',
        component: () => import('@/views/setting/PageSetting.vue'),
        meta: { title: 'Pengaturan' },
      },
      {
        path: 'theme',
        name: 'setting theme',
        component: () => import('@/views/setting/PageSettingTheme.vue'),
        meta: { title: 'Pengaturan Tema' },
      },
      {
        path: 'font',
        name: 'setting font',
        component: () => import('@/views/setting/PageSettingFont.vue'),
        meta: { title: 'Pengaturan Font' },
      },
    ],
  },
  // {
  //   path: '/maintenance',
  //   name: 'maintenance',
  //   component: () => import('@/views/errors/error_503.vue'),
  //   meta: { title: 'Maintenance' },
  // },
  // {
  //   path: '/:pathMatch(.*)*',
  //   component: () => import('@/views/errors/error_404.vue'),
  //   meta: { title: '404' },
  // },
]

/**
 * Generate route with layout main
 * @param path
 * @param children
 */
function getRoute (path: string, children: RouteRecordRaw[]): RouteRecordRaw {
  return {
    path,
    children,
    component: LayoutMain,
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const page = usePageStore()

  page.resetErrorState()
  if (!to.meta.title) {
    throw new Error('Page must have a title.')
  }

  setTimeout(() => {
    page.setTitle(to.meta.title)
  }, 250) // 50ms after route transition

  next()
})

export default router
<script setup lang="ts">
  import { useToastStore } from '@/stores/toast'

  const toast = useToastStore()
</script>

<template>
  <div class="toast-wrapper">
    <transition-group name="toast">
      <div v-for="item in toast.items" :key="item.id" class="toast-items">
        {{ item.message }}
      </div>
    </transition-group>
  </div>
</template>

<style lang="postcss" scoped>
.toast-wrapper {
  @apply fixed inset-x-0 flex flex-col pointer-events-none bottom-12 z-50;

  .toast-items {
    @apply px-2 py-1 mb-2 text-white max-w-xs mx-auto rounded text-xs;
    @apply bg-black bg-opacity-75;
    @apply transition-all ease-in-out duration-700;
  }

  .toast-enter-from,
  .toast-leave-to {
    opacity: 0;
  }
}
</style>
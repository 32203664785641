import { defineStore } from 'pinia'

const STORAGE_KEY = 'fontsize'
export const useFontStore = defineStore('Font', {
  state: () => ({
    value: localStorage.getItem(STORAGE_KEY) ?? '16px',
  }),
  getters: {
  },
  actions: {
    update (value = 16) {
      this.value = value + 'px'
      localStorage.setItem(STORAGE_KEY, this.value)
      this.setSize()
    },
    setSize () {
      document.documentElement.style.fontSize = this.value
    },
  },
})
<script setup lang="ts">
  import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid'
  import { ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'

  const router = useRouter()
  const route = useRoute()
  const search = ref(route.query.s as string)

  let debounce:NodeJS.Timeout
  watch(search, () => {
    clearTimeout(debounce)
    debounce = setTimeout(submit, 500)
  })
  const submit = () => {
    router.replace({ name: 'search', query: { s: search.value, t: route.query.t } })
  }
</script>

<template>
  <form class="relative mb-2" @submit.prevent="submit">
    <input
      v-model="search"
      type="search"
      placeholder="Cari lagu"
      class="rounded-full block text-lg w-full pl-6 pr-11 py-2 bg-primary-800 appearance-none">
    <div class="right-5 inset-y-0 absolute flex items-center">
      <MagnifyingGlassIcon class="w-5 h-5" />
    </div>
  </form>
</template>

<style lang="postcss" scoped>
  input[type="search"] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance:none;
      appearance: inherit;
    }
  }
</style>
import { App } from 'vue'
import AppNavbar from './AppNavbar.vue'
import AppSearch from './AppSearch.vue'

export default {
  install (app: App) {
    app.component('AppNavbar', AppNavbar)
    app.component('AppSearch', AppSearch)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    // AppTransitionFade: typeof AppTransitionFade,
    AppNavbar: typeof AppNavbar,
    AppSearch: typeof AppSearch,
  }
}